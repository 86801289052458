<template lang="pug">
div.container
  b-row(v-if="!member.membershipType")
    loading-spinner(message="Loading membership data...")
  div(v-if="member.membershipType")

    b-row(align-h="center")
      div.col-md-8
        b-alert(v-if="$route.params.result === 'success'" variant="success" show).text-center <strong>Membership renewal success!</strong> Thank you for your continued support to the cause. An email should hit your inbox soon with a confirmation.
        b-alert(v-if="$route.params.result === 'cancel'" variant="warning" show).text-center <strong>Payment cancelled.</strong> No money was charged from your card.

    b-row.text-center(align-h="center")
      h1 Good to see you, {{member.firstName}}!

    b-row(align-h="center").mt-5
      div.col-md-5.col-lg-4.p-2
        b-list-group
          b-list-group-item(active) Membership
          b-list-group-item.d-flex.justify-content-between.align-items-center ID <span>{{member.igdaFinlandId}}</span>
          b-list-group-item.d-flex.justify-content-between.align-items-center Type <span>{{member.membershipType}}</span>
          b-list-group-item.d-flex.justify-content-between.align-items-center(v-if="member.membershipType != 'Lifetime'") Status <span>{{membershipStatus}}</span>
          b-list-group-item.d-flex.justify-content-between.align-items-center Joined <span>{{member.createdDate | moment("MMMM YYYY")}}</span>
          b-list-group-item.d-flex.justify-content-between.align-items-center(v-if="member.membershipType != 'Lifetime' && membershipStatus == 'VALID'") Expires <span>{{member.validUntil | moment("MMMM YYYY")}}</span>
          b-list-group-item.d-flex.justify-content-between.align-items-center(v-else-if="membershipStatus == 'EXPIRED'") Expired <span>{{member.validUntil | moment("MMMM YYYY")}}</span>
          b-list-group-item.d-flex.justify-content-between.align-items-center(v-else-if="member.membershipType == 'Lifetime'") Expires <span>Never!</span>
      div.col-md-5.col-lg-4.p-2
        b-list-group
          b-list-group-item(active) Contact Information
          b-list-group-item.d-flex.justify-content-between.align-items-center Name <span>{{member.firstName}} {{member.lastName}}</span>
          b-list-group-item.d-flex.justify-content-between.align-items-center Email <span>{{member.email}}</span>
          b-list-group-item.d-flex.justify-content-between.align-items-center Residence <span>{{member.postOffice}}</span>

    b-row(align-h="center").mt-4
      p.text-center Please take a moment to make sure your details are up-to-date.

    b-row(align-h="center" align-v="center").mt-5
      b-button(variant="info" :to="'/individual-members/' + member._id + '/' + member.token + '/edit'") Edit Profile
      span(v-if="member.membershipType != 'Lifetime'")
        strong &nbsp;&nbsp; or &nbsp;&nbsp;
      b-button(variant="info" :to="'/individual-members/' + member._id + '/' + member.token + '/renew'" v-if="member.membershipType != 'Lifetime'") {{membershipStatus == 'EXPIRED' ? 'Renew' : 'Extend'}} Membership
</template>

<script>
import globalState from '@/main.js'

export default {
  data: function () {
    return {
      member: {
        _id: '',
        token: '',
        membershipType: null,
        firstName: '',
        lastName: '',
        email: '',
        postOffice: ''
      },
      globalState,
      now: new Date()
    }
  },
  computed: {
    membershipStatus: function () {
      if (this.member.membershipType === 'Lifetime') { return 'LIFETIME' } else if (this.member.validUntil > this.now) { return 'VALID' } else { return 'EXPIRED' }
    }
  },
  mounted: function () {
    this.axios.get('/api/individual-members/editWithToken/' + this.$route.params.id + '/' + this.$route.params.token)
      .then(res => {
        this.member = res.data
        this.member.validUntil = new Date(res.data.validUntil)
        this.member.createdDate = new Date(res.data.createdDate)
      })
      .catch(err => {
        this.$notify({
          group: 'error',
          type: 'error',
          title: 'Fetching member details failed',
          text: err.hasOwnProperty('response') ? err.response.data : err,
          duration: -1
        })
      })
  }
}
</script>
